@mixin colors {
  --primary-50: #EDFAF8;
  --primary-100: #D3F3EE;
  --primary-200: #B5ECE3;
  --primary-300: #97E4D8;
  --primary-400: #81DECF;
  --primary-500: #6BD8C7;
  --primary-600: #63D4C1;
  --primary-700: #58CEBA;
  --primary-800: #4EC8B3;
  --primary-900: #3CBFA6;

  --secondary-50: #E5E5E5;
  --secondary-100: #BFBFBF;
  --secondary-200: #959595;
  --secondary-300: #6A6A6A;
  --secondary-400: #444444;
  --secondary-500: #2A2A2A;
  --secondary-600: #252525;
  --secondary-700: #1F1F1F;
  --secondary-800: #191919;
  --secondary-900: #0F0F0F;

  --tertiary-50: #F0F0F0;
  --tertiary-100: #D9D9D9;
  --tertiary-200: #C0C0C0;
  --tertiary-300: #A6A6A6;
  --tertiary-400: #939393;
  --tertiary-500: #808080;
  --tertiary-600: #787878;
  --tertiary-700: #6D6D6D;
  --tertiary-800: #636363;
  --tertiary-900: #505050;

  --dark-bg: #141414;
  --dark-error: #D86B7C;
  --dark-line-accent: #2C4743;
  --dark-line-low-accent: #19201F;
  --dark-elipse-grain: linear-gradient(90deg, #9BFFF0 0%, rgba(246, 255, 254, 0.18) 100%);
  --dark-elipse-gradient: linear-gradient(90deg, rgba(155, 255, 240, 0.50) 0%, rgba(246, 255, 254, 0.00) 100%);
  --dark-line-shape: rgba(155, 188, 183, 0.09);

  --light-bg: #F6F6F6;
  --light-error: #D0485D;
  --light-line-accent: #C7C7C7;
  --light-line-low-accent: #EAEAEA;
  --light-elipse-grain: linear-gradient(328deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.04) 100%);
  --light-line-shape: #E3E3E3;

  --white: #FFFFFF;
}