@import 'src/styles/helpers';

.cookiesPolicyBanner {
  position: fixed;
  bottom: 44px;
  left: 200px;
  width: 484px;
  border-radius: 16px;
  @include setProperty(background, var(--secondary-800), var(--secondary-50));
  z-index: 5;

  @include media(desktopSmall) {
    left: 120px;
  }

  @include media(desktopBig) {
    left: 320px;
  }

  @include media(desktopHuge) {
    left: 320px;
  }

  @include media(landscape) {
    left: 72px;
  }

  @include media(portrait) {
    left: 0;
    bottom: 0;
    width: 100%;
  }

  @include media(mobile) {
    left: 0;
    bottom: 0;
    width: 100%;
  }

}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 48px;

  @include media(portrait) {
    padding: 24px;
    gap: 24px;
  }

  @include media(mobile) {
    padding: 24px;
    gap: 24px;
  }
}

.text {
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.183px;

  @include media(mobile) {
    font-size: 14px;
    line-height: 28px;
  }

  & > a {
    text-decoration: underline;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 32px;

  @include media(mobile) {
    flex-direction: column;
    gap: 24px;
  }
}
